import React, {useId, useMemo, useState} from 'react';
import {
  Alert,
  Card,
  InputGroup,
  Form,
  Collapse,
  Modal,
  Badge,
  Button,
  BadgeProps,
} from 'react-bootstrap';
import * as FormikFormControls from 'client/form/FormikFormControls';
import {useField} from 'formik';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import classNames from 'classnames';
import { CustomerCompanyMonitoredSettings as CustomerCompanyMonitoredSettingsFormData } from 'client/customer/types';
import {Edit3, Eye, Info} from 'react-feather';
import IdProvider from 'client/components/IdProvider';
import {UseModalStateProps} from 'client/hooks/useModalState';
import {FormCheckInputProps} from 'react-bootstrap/esm/FormCheckInput';
import { CompanyEventMetaWatchableEvent } from 'client/companyEvent/types';
import { CompanyMonitoredMeta } from 'client/companyMonitored/types';


interface ContactFrequencyDescriptionProps {
  values: CustomerCompanyMonitoredSettingsFormData;
}

export function ContactFrequencyDescription (props: ContactFrequencyDescriptionProps) {
  const { values } = props;
  const {
    contact_frequency:frequency,
    contact_frequency_weekly_anchor:weekly_anchor,
    contact_frequency_monthly_anchor:monthly_anchor,
    contact_email:email,
    email_group:group = 'single',
  } = values;
  if (!frequency || !group) return null;
  if (frequency === 'weekly' && !weekly_anchor) return null;
  if (frequency === 'monthly' && !monthly_anchor) return null;
  return (
    <InfoAlert>
      <span>
        {' '}{group === 'single' && <>Ett enda e-postmeddelande med alla uppdateringarna </>}
        {' '}{group === 'org_number' && <>Ett e-postmeddelande per (uppdaterat) företag </>}
        {frequency === 'daily' && 'kommer att skickas max en gång varje dag'}
        {frequency === 'weekly' && `kommer att skickas max en gång varje vecka på ${dayOfWeek(weekly_anchor)}`}
        {frequency === 'monthly' && `kommer att skickas max en gång varje månad den ${dateOfMonthOrdinal(monthly_anchor)}`}
        {email ? (
          <> till <strong>{email}</strong></>
        ) : (
          <> till användarkontots e-postadress.</>
        )}
      </span>
    </InfoAlert>
  );
}

interface WatchLevelRadioProps extends FormCheckInputProps {
  className?: string;
  name: string;
  value: string;
  label: string;
  subLabel: string;
}

function WatchLevelRadio (props: WatchLevelRadioProps) {
  const { className, name, value, label, subLabel, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    value,
    type: 'radio',
  });

  return (
    <IdProvider>
      {id => (
        <label className={classNames('d-flex cursor-pointer', className)}>
          <span className="px-2 py-0 small ps-3 d-flex align-items-center justify-content-center">
            <Form.Check.Input
              type="radio"
              id={id}
              isInvalid={Boolean(meta.error)}
              {...field}
              {...restOfProps}
            />
          </span>
          <span className="px-2 py-0" style={{fontSize: '85%', lineHeight: '1.25em'}}>
            <em className="label d-block">
              {label}
            </em>
            <span className="small d-block" style={{fontSize: '75%'}}>
              {subLabel}
            </span>
          </span>
        </label>
      )}
    </IdProvider>
  );
}

interface WatchGroupProps {
  className?: string;
  groupName: string;
  label: string;
  subLabel: string;
  meta: CompanyMonitoredMeta;
}

export function WatchGroup (props: WatchGroupProps) {
  const { className, groupName, label, subLabel, meta } = props;

  const watchableEvents: CompanyEventMetaWatchableEvent[] = useMemo(() => {
    if (!meta?.watchable_company_events) return [];
    return Object.values(meta.watchable_company_events).filter(obj => obj.group === groupName);
  }, [groupName, meta]);

  const [activeField, activeMeta] = useField({
    name: `watch_${groupName}`,
    type: 'checkbox',
  });

  const [levelField] = useField({
    name: `watch_${groupName}_level`,
  });

  const [watchedField, , watchedHelpers] = useField<string[]>({
    name: `watch_${groupName}_events`,
  });

  const onChangeValueWatchedCompanyEvents = (newValues: string[]) => {
    watchedHelpers.setTouched(true);
    watchedHelpers.setValue(newValues);
  };

  const watchedEventCount: number = useMemo(() => {
    if (!levelField.value) return 0;
    return watchableEvents.reduce((sum, obj) => {
      if (levelField.value === 'recommended') {
        return sum + (obj.recommended ? 1 : 0);
      }
      return sum + (watchedField.value.includes(obj.value) ? 1 : 0);
    }, 0);
  }, [watchableEvents, levelField.value, watchedField.value]);

  const modalProps = {
    label,
    subLabel,
    watchableEvents,
    level: levelField.value,
    value: watchedField.value,
    onChangeValue: onChangeValueWatchedCompanyEvents,
  };

  return (
      <Card className={classNames(className, 'rounded-3')}>
        <IdProvider>
          {id => (
            <label className="d-flex cursor-pointer">
              <span className="p-2 ps-3 d-flex align-items-center justify-content-center">
                <Form.Check.Input
                  id={id}
                  isInvalid={Boolean(activeMeta.error)}
                  {...activeField}
                />
              </span>
              <span className="p-2">
                <strong className="label d-block">
                  {label}
                </strong>
                <span className="small d-block">
                  {subLabel}
                </span>
              </span>
            </label>
          )}
        </IdProvider>
        <Collapse in={activeField.checked}>
          <div>
            <div className="border-top">
              <WatchLevelRadio
                className="my-1 mt-2"
                name={`watch_${groupName}_level`}
                value="recommended"
                label="Rekommenderat"
                subLabel="Calculates rekommenderade inställningar"
              />
              <WatchLevelRadio
                className="my-1 mb-2"
                name={`watch_${groupName}_level`}
                value="custom"
                label="Anpassat"
                subLabel="Välj själv vilka händelser du vill bli meddelad om"
              />
            </div>
            <div className="d-flex flex-wrap gap-2 align-items-center p-2 border-top">
              <ModalOpeningButton
                className="py-0 d-flex align-items-center gap-1"
                title="Visa kolumninställningar"
                Modal={WatchEventTypesModal}
                modalProps={modalProps}
                variant={levelField.value === 'custom' ? 'outline-primary' : 'outline-secondary'}
                size="sm"
              >
                {levelField.value === 'custom' ? (
                  <>
                    <Edit3 size={14} />
                    {' '}Välj händelser
                  </>
                ) : (
                  <>
                    <Eye size={14} />
                    {' '}Visa händelser
                  </>
                )}
              </ModalOpeningButton>
              <WatchedCountBadge count={watchedEventCount} total={watchableEvents.length} />
            </div>
          </div>
        </Collapse>
      </Card>
  );
}

interface WatchedCountBadgeProps extends BadgeProps {
  count?: number;
  total?: number;
}

function WatchedCountBadge (props: WatchedCountBadgeProps) {
  const { count, total, ...restOfProps } = props;
  if (typeof count !== 'number' || typeof total !== 'number') return null;
  let bg = 'secondary';
  if (count === 0 && total > 0) bg = 'danger';
  if (count >= total) bg = 'success';
  return (
    <Badge {...restOfProps} bg={bg}>
      {count}/{total} händelser
    </Badge>
  );
}

interface WatchEventTypesModalProps extends UseModalStateProps {
  watchableEvents: CompanyEventMetaWatchableEvent[];
  value: string[];
  level: string;
  label: string;
  subLabel: string;
  onChangeValue: (newValues: string[]) => void;
}

export function WatchEventTypesModal (props: WatchEventTypesModalProps) {
  const {
    show,
    onHide,
    onExited,
    level,
    watchableEvents,
    label,
    subLabel,
    value:outerValue,
    onChangeValue,
  } = props;

  const disabled = level === 'recommended';

  const [value, setValue] = useState<string[]>(outerValue);
  const [filter, setFilter] = useState<string>('');

  const onChangeFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(ev.target.value);
  };

  const onChangeWatchableEventCheckboxValue = (name: string, checked: boolean) => {
    const newValue = checked ? [...value, name] : value.filter(v => v !== name);
    setValue(newValue);
  };

  const onClickResetFilter = () => {
    setFilter('');
  };

  const onClickToggleSelection = () => {
    const selected = filteredWatchableEvents.map(obj => obj.value);
    if (allSelected) {
      // unselect everything when toggling and all is selected
      const newValue = value.filter(v => !selected.includes(v));
      setValue(newValue);
    } else {
      // select all non-selected when toggling and not all is selected
      const newValue = [...value, ...selected.filter(v => !value.includes(v))];
      setValue(newValue);
    }
  };

  const filteredWatchableEvents = useMemo(() => {
    if (!filter) return watchableEvents;
    const regExp = new RegExp(`${filter}`, 'i');
    return watchableEvents.filter(obj => {
      return [obj.value, obj.description, obj.label].some(str => str ? regExp.test(str) : false);
    });
  }, [filter, watchableEvents]);

  const allSelected = useMemo(() => {
    return filteredWatchableEvents.every(obj => value.includes(obj.value));
  }, [value, filteredWatchableEvents]);

  const onSaveAndHide = () => {
    onChangeValue(value);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      centered
      backdrop={disabled ? undefined : 'static'}
    >
      <Modal.Header closeButton={disabled}>
        <Modal.Title as="h5">
          {label}
          <small className="d-block text-secondary" style={{fontSize: '70%'}}>{subLabel}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-bottom">
        <p className="small mb-0">
          Här kan du {disabled ? ' se ' : ' redigera '} de möjliga bevakningshändelserna i denna grupp.
        </p>
      </Modal.Body>
      <Modal.Body className="border-bottom d-flex flex-wrap gap-2">
        <Form.Group className="flex-grow-1">
          <Form.Control
            name="filter"
            value={filter}
            onChange={onChangeFilter}
            placeholder="Filtrera händelser"
            size="sm"
          />
        </Form.Group>
        <Button
          onClick={onClickResetFilter}
          variant="outline-primary"
          size="sm"
          disabled={!filter}
        >
          Återställ
        </Button>
        {!disabled && (
          <Button
            onClick={onClickToggleSelection}
            variant="outline-primary"
            size="sm"
          >
            {allSelected ? 'Avmarkera alla' : 'Markera alla'}
          </Button>
        )}
      </Modal.Body>
      <Modal.Body>
        {filteredWatchableEvents.map(obj => (
          <WatchedEventCheckbox
            level={level}
            key={obj.value}
            obj={obj}
            checked={value.includes(obj.value)}
            onChangeValue={onChangeWatchableEventCheckboxValue}
            disabled={disabled}
          />
        ))}
        {!filteredWatchableEvents.length && <div>Det finns inga filter här.</div>}
      </Modal.Body>
      <Modal.Footer>
        {disabled ? (
          <Button variant="outline-secondary" onClick={onHide}>
            Stäng
          </Button>
        ) : (
          <div className="d-flex flex-wrap gap-2">
            <Button variant="outline-secondary" onClick={onHide}>
              Stäng
            </Button>
            <Button onClick={onSaveAndHide} variant="outline-primary">
              Spara och stäng
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

interface WatchedEventCheckboxProps {
  obj: CompanyEventMetaWatchableEvent;
  level: string;
  checked: boolean;
  disabled: boolean;
  onChangeValue: (name: string, checked: boolean) => void;
}

function WatchedEventCheckbox (props: WatchedEventCheckboxProps) {
  const { obj, level, disabled, onChangeValue, checked:outerChecked } = props;

  // TODO add red asterik for recommended event
  const label = obj.description ? <>{obj.label}<br /><small>{obj.description}</small></> : <>{obj.label}</>;
  const checked = outerChecked || (level === 'recommended' && obj.recommended);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(ev.target.name, ev.target.checked);
  };

  const id = useId();

  return (
    <Form.Check
      id={id}
      className="mb-1"
      key={obj.value}
      type="checkbox"
      name={obj.value}
      checked={checked}
      disabled={disabled}
      label={label}
      onChange={onChange}
    />
  );
}

function InfoAlert (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Alert variant="secondary" className="small m-0 py-2 p-2 d-flex align-items-center gap-2">
      <span>
        <Info size={22} />
      </span>
      {children}
    </Alert>
  );
}

function dateOfMonthOrdinal (dateOfMonth?: any): string {
  if (dateOfMonth < 3) return dateOfMonth + ':a';
  return dateOfMonth + ':e';
}

function dayOfWeek (dayOfWeek?: any): string {
  switch (String(dayOfWeek)) {
    default: return '';
    case '0': return 'söndagar';
    case '1': return 'måndagar';
    case '2': return 'tisdagar';
    case '3': return 'onsdagar';
    case '4': return 'torsdagar';
    case '5': return 'fredagar';
    case '6': return 'lördagar';
  }
}
export function SettingsFormContactFrequencyControl () {
  return (
    <FormikFormControls.Select
      label="Kontaktfrekvens"
      name="contact_frequency"
      required
    >
      <option value="daily">Dagligen</option>
      <option value="weekly">Per vecka</option>
      <option value="monthly">Per månad</option>
    </FormikFormControls.Select>
  );
}

export function SettingsFormContactFrequencyWeeklyAnchorControl () {
  return (
    <FormikFormControls.Select
      label="Veckodag"
      name="contact_frequency_weekly_anchor"
      required
    >
      <option value="">Ange en veckodag</option>
      <option value="1">Måndag</option>
      <option value="2">Tisdag</option>
      <option value="3">Onsdag</option>
      <option value="4">Torsdag</option>
      <option value="5">Fredag</option>
      <option value="6">Lördag</option>
      <option value="0">Söndag</option>
    </FormikFormControls.Select>
  );
}

export function SettingsFormContactFrequencyMonthlyAnchorControl () {
  return (
    <FormikFormControls.InputGroup
      label="Månadsdatum"
      type="number"
      name="contact_frequency_monthly_anchor"
      required
      step={1}
      min={1}
      max={28}
      before={<InputGroup.Text>Den</InputGroup.Text>}
      placeholder="1-28"
    />
  );
}

export function SettingsFormEmailGroupControl () {
  return (
    <FormikFormControls.Select
      label="Utskickstyp"
      name="email_group"
      required
    >
      <option value="single">Samlingsmeddelande</option>
      <option value="org_number">Ett meddelande per företag</option>
    </FormikFormControls.Select>
  );
}
