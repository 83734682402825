import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card, Col, Row } from 'react-bootstrap';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import SaveButton from 'client/buttons/SaveButton';
import ErrorAlert from 'client/components/ErrorAlert';
import classNames from 'classnames';
import {
  IUser,
  UserCompanyMonitoredSettings as UserCompanyMonitoredSettingsFormData,
} from 'client/user/types';
import BlockSpinner from 'client/spinners/BlockSpinner';
import * as CompanyMonitoredUtils from 'client/companyMonitored/CompanyMonitoredUtils';
import {CompanyMonitoredMeta} from 'client/companyMonitored/types';

interface UserCompanyMonitoredSettingsProps {
  className?: string;
  userId?: string;
}

const defaultInitialValues = {
  email_group: 'single',
  contact_frequency: 'daily',
  contact_frequency_weekly_anchor: '1',
  contact_frequency_monthly_anchor: 1,
  contact_email: '',

  watch_status: false,
  watch_status_level: 'recommended',
  watch_status_events: [],

  watch_credit: false,
  watch_credit_level: 'recommended',
  watch_credit_events: [],

  watch_accounts: false,
  watch_accounts_level: 'recommended',
  watch_accounts_events: [],
};

export default function UserCompanyMonitoredSettings (props: UserCompanyMonitoredSettingsProps) {
  const { className, userId = 'me' } = props;

  const queryClient = useQueryClient();

  const query = useQuery<IUser['company_monitored_settings']>({
    queryKey: [`/api/user_company_monitored/${userId}/settings`],
  });
  
  const metaQuery = useQuery<CompanyMonitoredMeta>({
    queryKey: ['/api/user_company_monitored/me/meta'],
  });

  const mutation = useMutation<UserCompanyMonitoredSettingsFormData, Error, UserCompanyMonitoredSettingsFormData>({
    mutationFn: form => axios.put(`/api/user_company_monitored/${userId}/settings`, formToUpdate(form)).then(r => r.data),
    onSuccess: settings => {
      requestCallbacks.onSuccess('Kontaktinställningarna har uppdaterats');
      queryClient.setQueryData([`/api/user_company_monitored/${userId}/settings`], settings);
    },
  });

  const initialValues = {...defaultInitialValues, ...(query.data ?? {})};

  return (
    <Card className={classNames(className)}>
      <ErrorAlert className="m-3" error={query.error || metaQuery.error} />
      <BlockSpinner isLoading={query.isLoading || metaQuery.isLoading} />
      {query.isSuccess && metaQuery.isSuccess && (
        <UserCompanyMonitoredSettingsForm
          initialValues={initialValues}
          onSave={mutation.mutateAsync}
          error={mutation.error}
          meta={metaQuery.data}
        />
      )}
    </Card>
  );
}

interface UserCompanyMonitoredSettingsFormProps {
  initialValues: UserCompanyMonitoredSettingsFormData;
  error: null | Error;
  onSave: (form: UserCompanyMonitoredSettingsFormData) => Promise<any>;
  meta: CompanyMonitoredMeta;
}

function UserCompanyMonitoredSettingsForm (props: UserCompanyMonitoredSettingsFormProps) {
  const { onSave, error, initialValues, meta } = props;

  const onSubmit = async (form: UserCompanyMonitoredSettingsFormData, helpers: FormikHelpers<UserCompanyMonitoredSettingsFormData>) => {
    await onSave(form);
    helpers.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {formProps => (
        <FormikForm>
          <div>
            <h5 className="mt-3 mx-3 mb-0 pb-1 border-bottom">
              Välj hur ofta du vill bli meddelad om nya händelser
            </h5>
            <Row className="p-3 pt-0">
              <Col md={6} lg={4}>
                <CompanyMonitoredUtils.SettingsFormContactFrequencyControl />
              </Col>
              {['weekly', 'monthly'].includes(formProps.values.contact_frequency as any) && (
                <Col md={6} lg={4}>
                  {formProps.values.contact_frequency === 'weekly' && (
                    <CompanyMonitoredUtils.SettingsFormContactFrequencyWeeklyAnchorControl />
                  )}
                  {formProps.values.contact_frequency === 'monthly' && (
                    <CompanyMonitoredUtils.SettingsFormContactFrequencyMonthlyAnchorControl />
                  )}
                </Col>
              )}
              <Col md={6} lg={4}>
                <CompanyMonitoredUtils.SettingsFormEmailGroupControl />
              </Col>
            </Row>
            <div className="px-3 mb-3">
              <CompanyMonitoredUtils.ContactFrequencyDescription values={formProps.values} />
            </div>
            <h5 className="m-3 pb-1 border-bottom">
              Välj vilka händelser du vill bli meddelad om
            </h5>
            <div className="px-3">
              <Row>
                <Col lg={4} md={6}>
                  <CompanyMonitoredUtils.WatchGroup
                    className="mb-3"
                    groupName="status"
                    label="Företagsstatus"
                    subLabel="Status, adress och styrelse m.m."
                    meta={meta}
                  />
                </Col>
                <Col lg={4} md={6}>
                  <CompanyMonitoredUtils.WatchGroup
                    className="mb-3"
                    groupName="credit"
                    label="Kreditvärdighet och risker"
                    subLabel="Betalningsproblem, riskbedömning m.m."
                    meta={meta}
                  />
                </Col>
                <Col lg={4} md={6}>
                  <CompanyMonitoredUtils.WatchGroup
                    className="mb-3"
                    groupName="accounts"
                    label="Bokslut"
                    subLabel="Ändrade bokslut och nyckeltal m.m."
                    meta={meta}
                  />
                </Col>
              </Row>
            </div>
            <Card.Footer className="p-3">
              <SaveButton
                type="submit"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || !Object.keys(formProps.touched).length || formProps.isSubmitting}
              >
                Spara inställningar
              </SaveButton>
            </Card.Footer>
          </div>
          <ErrorAlert className="m-3 mt-0" error={error} />
        </FormikForm>
      )}
    </Formik>
  );
}

function formToUpdate (form: UserCompanyMonitoredSettingsFormData): any {
  const {
    contact_frequency,
    contact_frequency_weekly_anchor,
    contact_frequency_monthly_anchor,
    ...restOfProps
  } = form;
  const result: any = {contact_frequency, ...restOfProps};
  if (contact_frequency === 'monthly') result.contact_frequency_monthly_anchor = contact_frequency_monthly_anchor ?? 1;
  if (contact_frequency === 'weekly') result.contact_frequency_weekly_anchor = parseInt((contact_frequency_weekly_anchor as string) ?? '1', 10) || 1;
  return result;
}
