import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CompanyEventRowWithRelations } from 'client/companyEvent/types';
import { TableSpinningOverlay, TableCardFooterWithPagination } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import * as CompanyEventFormatters from 'client/companyEvent/CompanyEventFormatters';
import { TableProvider } from 'client/contexts/TableContext';

export interface CompanyEventTableFilterParams {
  org_number?: string;
  event_type?: string;
  created_at_from?: string;
  created_at_to?: string;
  event_date_from?: string;
  event_date_to?: string;
}

interface CompanyEventRowWithId extends CompanyEventRowWithRelations {
  id: string;
}

interface ListData {
  rows: CompanyEventRowWithRelations[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'event_date',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CompanyEventTablePage () {
  const {
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    setFilterParams,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/as_customer/company_event/list', params],
    placeholderData: keepPreviousData,
  });

  const rows: CompanyEventRowWithId[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({
      id: [row.org_number, row.event_type, row.event_date, row.event_type_mod].filter(v => v).join('_'),
      ...row,
    }));
  }, [query.data?.rows]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Företagshändelser</title>
        </Helmet>
        <PageHeader>Företagshändelser</PageHeader>
        <p>
          Denna tabell visar händelser på de företag du har lagt upp för bevakning.
        </p>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}

              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}

              onReload={query.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'select',
  'org_number',
  'event_date',
  'event_type',
  'prev',
  'curr',
  'diff',
  'actions',
];

const columnSortable = [
  'org_number',
  'event_date',
  'event_type',
  'value_from',
  'value_to',
  'created_at',
  'updated_at',
];

type FilterDefinitionFactory = () => FilterDefinition[];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.date(['event_date_from', 'Från']),
  filterDefs.date(['event_date_to', 'Till']),
  filterDefs.companyEventTypeSelectGroup(['event_type', 'Typ'], {
    extraProps: {placeholder: 'Oavsett typ'},
  }, '/api/as_customer/company_event/event_types'),
];

type ColumnDefinitionFactory = () => ColumnDefinition<CompanyEventRowWithId>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.select(),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.date(['event_date', 'Datum']),
  columnDefs.cell(['event_type', 'Typ'], props => (
    <CompanyEventFormatters.CompanyEventType value={props.row.fmt_event_type} />
  )),
  columnDefs.cell(['change', 'Förändring'], props => (
    <CompanyEventFormatters.CompanyEventChange value={props.row} />
  )),
  columnDefs.cell(['prev', 'Från'], props => (
    <CompanyEventFormatters.CompanyEventPrev value={props.row} />
  )),
  columnDefs.cell(['value_prev', 'Värde från'], props => (
    <CompanyEventFormatters.CompanyEventValue value={props.row.value_prev} />
  )),
  columnDefs.cell(['curr', 'Till'], props => (
    <CompanyEventFormatters.CompanyEventCurr value={props.row} />
  )),
  columnDefs.cell(['value_to', 'Värde till'], props => (
    <CompanyEventFormatters.CompanyEventValue value={props.row.value_curr} />
  )),
  columnDefs.cell(['value', 'Värde'], props => (
    <CompanyEventFormatters.CompanyEventValueChange value={props.row} />
  )),
  columnDefs.cell(['text', 'Text'], props => (
    <CompanyEventFormatters.CompanyEventTextChange value={props.row} />
  )),
  columnDefs.cell(['diff', 'Differens'], props => (
    <CompanyEventFormatters.CompanyEventDiff value={props.row} />
  )),
  columnDefs.cell(['text_prev', 'Text från'], props => (
    <CompanyEventFormatters.CompanyEventText value={props.row.text_prev} />
  )),
  columnDefs.cell(['text_to', 'Text till'], props => (
    <CompanyEventFormatters.CompanyEventText value={props.row.text_curr} />
  )),

  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
