import React, { useMemo, useState } from 'react';
import { Alert, Badge, Card } from 'react-bootstrap';
import classNames from 'classnames';
import { UserCompanyMonitoredRowWithRelations } from 'client/userCompanyMonitored/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { useMutation, useQuery } from '@tanstack/react-query';
import CompanySearch from 'client/components/CompanySearch';
import useRemount from 'client/hooks/useRemount';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import LoadingButton from 'client/buttons/LoadingButton';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import axios from 'client/axios';
import { TableProvider } from 'client/contexts/TableContext';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import Pluralizer from 'client/components/Pluralizer';
import useTableSelectRows, { SelectedRows, UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import TableBatchForm from 'client/table/TableBatchForm';
import CompanyBatchSearchButton from 'client/company/CompanyBatchSearchButton';

interface CompanyMonitoringCardProps {
  className?: string;
}

interface CustomerCompanyMonitoredQueryResponse {
  rows: UserCompanyMonitoredRowWithRelations[];
  limit: number;
  email: string;
}

interface TableRow extends UserCompanyMonitoredRowWithRelations {
  id: string;
}

export default function UserCompanyMonitoredTableCard (props: CompanyMonitoringCardProps) {
  const { className } = props;
  const [orgNumber, setOrgNumber] = useState<string>('');

  const orgNumberRemount = useRemount();

  const query = useQuery<CustomerCompanyMonitoredQueryResponse, Error>({
    queryKey: ['/api/user_company_monitored/me'],
  });

  const data = query.data;

  const rows: TableRow[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({...row, id: row.org_number}));
  }, [query.data?.rows]);

  const rowIds = useMemo(() => {
    return rows.map(r => r.id);
  }, [rows]);

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: orgNumber => axios.delete(`/api/user_company_monitored/me/company/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      query.refetch();
    },
  });

  const [selectedRows, setSelectedRows] = useState<SelectedRows>({});
  const tableSelectRows = useTableSelectRows(rowIds, selectedRows, setSelectedRows);

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: orgNumber => axios.put(`/api/user_company_monitored/me/company/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      query.refetch();
    },
  });

  const batchMutation = useMutation<unknown, Error, string[]>({
    mutationFn: async orgNumbers => {
      const result = await axios.post<any, any[]>('/api/user_company_monitored/me/batch', {
        action: 'monitor',
        org_numbers: orgNumbers,
      });
      await query.refetch();
      requestCallbacks.onSuccess('Bevakningen har lagts till på företagen');
      return result;
    },
  });

  const onSubmitCreate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  };
  
  const orgNumberErrorExists = useMemo(() => rows.some(item => {
    if (!orgNumber) return false;
    return item.org_number === orgNumber;
  }), [rows, orgNumber]);

  const columns = columnFactory(deleteMutation.mutateAsync);

  const limitReached = data && rows ? data.limit - rows.length <= 0 : false;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 p-3">
        <Card.Title as="h6" className="mb-0 p-0 d-flex flex-wrap gap-2 justify-content-between align-items-center">
          <span>
            Bevakade företag{' '}
            {data && rows && (
              <Badge bg="secondary">{Math.max(0, data.limit - rows.length)}/{data.limit} företag kvar</Badge>
            )}
          </span>
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      <CardBodyErrorAlert error={batchMutation.error} />
      {query.isSuccess && data && limitReached && (
        <Card.Body className="border border-bottom-0">
          <Alert className="mt-3 mb-1">
            Maxgränsen för hur många företag som går att bevaka är nådd.
          </Alert>
        </Card.Body>
      )}
      <Card.Body className="border border-bottom-0 d-flex gap-2 flex-wrap">
        <form className="d-flex gap-2 flex-wrap flex-grow-1" onSubmit={onSubmitCreate}>
          {orgNumberRemount.mounted && (
            <CompanySearch
              className="w-auto flex-grow-1"
              onSelect={setOrgNumber}
              defaultInputValue={orgNumber}
            />
          )}
          <LoadingButton
            type="submit"
            disabled={!orgNumber || createMutation.isPending || orgNumberErrorExists || limitReached}
            isLoading={createMutation.isPending}
          >
            Lägg till bevakning
          </LoadingButton>
        </form>
        <CompanyBatchSearchButton
          title="Lägg till många organisationsnummer samtidigt"
          onSelect={batchMutation.mutateAsync}
          disabled={limitReached}
        >
          Lägg till flera företag
        </CompanyBatchSearchButton>
      </Card.Body>
      <UserCompanyMonitoredTableBatchForm
        tableSelectRows={tableSelectRows}
        onReload={query.refetch}
      />
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <TableProvider tableSelectRows={tableSelectRows}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 border align-middle"
              columns={columns}
              rows={rows}
              emptyMessage="Inga företag är bevakade"
            />
          </div>
        </TableProvider>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 py-3">
        Totalt {rows.length}{' '}
        <Pluralizer
          count={rows.length}
          zero="bevakade företag"
          one="bevakat företag"
          more="bevakade företag"
        />
      </Card.Footer>
    </Card>
  );
}

type ColumnFactory = (deleteMutateAsync: (orgNumber: string) => Promise<any>) => ColumnDefinition[];

const columnFactory: ColumnFactory = deleteMutateAsync => [
  columnDefs.select(),
  columnDefs.cell(['name', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.cell(['status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.cs_basic.status_text_high} />
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  // columnDefs.cell(['settings', 'Inställningar'], props => (
  //   <>
  //     {props.row.settings === null ? '-' : 'Specifika'}
  //   </>
  // )),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      <ConfirmDeleteButton
        size="sm"
        className="px-1"
        onConfirmedDelete={() => deleteMutateAsync(props.row.org_number)}
        confirmMessage="Är du säker på att du vill ta bort bevakningen av detta företag?"
        buttonTitle="Ta bort bevakningen"
        buttonLabel=""
      />
    </div>
  )),
];


interface UserCompanyMonitoredTableBatchFormProps {
  tableSelectRows: UseTableSelectRows;
  onReload?: () => Promise<any>;
}

function UserCompanyMonitoredTableBatchForm (props: UserCompanyMonitoredTableBatchFormProps) {
  const { tableSelectRows, onReload } = props;

  const batchMutation = useMutation<any[], Error, string>({
    mutationKey: ['UserCompanyMonitoredTableBatchForm'],
    mutationFn: async action => {
      const result = await axios.post<any, any[]>('/api/user_company_monitored/me/batch', {
        action,
        org_numbers: tableSelectRows.selectedRowIds,
      });
      if (onReload) await onReload();
      tableSelectRows.setSelectedRows({});
      requestCallbacks.onSuccess('Åtgärden har utförts');
      return result;
    },
  });

  const actions = [
    {value: 'unmonitor', label: 'Ta bort bevakningen'},
  ];

  return (
    <TableBatchForm
      className="border border-bottom-0 d-flex flex-wrap align-items-center gap-2 p-2 small"
      tableSelectRows={tableSelectRows}
      batchMutation={batchMutation}
      actions={actions}
      initialAction="unmonitor"
    />
  );
}
