import React, { useMemo, useState } from 'react';
import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { Table, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import { AxiosError } from 'axios';
import axios from 'client/axios';
import LoadingButton from 'client/buttons/LoadingButton';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import CustomerForm, { CreateCustomerFormFields } from 'client/customer/CustomerForm';
import { ICustomer, ICustomerWithReportCounts } from 'client/customer/types';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';
import IdProvider from 'client/components/IdProvider';
import ErrorAlert from 'client/components/ErrorAlert';
import SaveButton from 'client/buttons/SaveButton';

interface ISelectCustomerModal {
  show: boolean;
  onHide: () => void;
  onExited: () => void;
  onSelectCustomer: (customer: ICustomer) => void | Promise<any>;
  initialCreateFormValues?: Partial<CreateCustomerFormFields>;
  buttonSelectExistingLabel?: string;
  buttonCreateAndSelectNewLabel?: string;
  modalTitle?: string;
}

const SelectCustomerModal: React.FC<ISelectCustomerModal> = props => {
  const {
    onSelectCustomer,
    show,
    onHide,
    onExited,
    initialCreateFormValues,
    buttonSelectExistingLabel = 'Välj markerad kund',
    buttonCreateAndSelectNewLabel = 'Skapa och välj kund',
    modalTitle = 'Välj en kund',
  } = props;

  const [tab, setTab] = useState<'search' | 'create'>('search');

  const initialFormValues = useMemo(
    () => ({
      email: '',
      name: '',
      id: '',
      contact_person: '',
      language: 'sv',
      ...(initialCreateFormValues || {}),
    }),
    [initialCreateFormValues],
  );

  const createMutation = useMutation<ICustomer, AxiosError, CreateCustomerFormFields>({
    mutationFn: data => axios.post('/api/customers', data).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kunden skapades');
    },
  });

  const onClose = () => {
    setTab('search');
    onHide();
  };

  const onSubmitCreate = async (form: CreateCustomerFormFields, helpers: FormikHelpers<CreateCustomerFormFields>) => {
    return createMutation.mutateAsync(form)
    .then(createdCustomer => {
      const p = onSelectCustomer(createdCustomer);
      if (!(p instanceof Promise)) {
        onClose();
        return;
      }
      return p.finally(() => {
        onClose();
      });
    }).catch(err => {
      helpers.setSubmitting(false);
      const errorMap = errorUtils.maybeAxiosErrorToErrorMap(err);
      if (errorMap) {
        helpers.setErrors(errorMap);
        return;
      }
      throw err;
    });
  };

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} size="lg" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Tabs
          className="mb-4"
          activeKey={tab}
          transition={false}
          onSelect={tab => setTab(tab as any)}
        >
          <Tab eventKey="search" title="Sök efter en befintlig kund">
            <SelectCustomerModalSearchTab
              buttonSelectExistingLabel={buttonSelectExistingLabel}
              onSelectCustomer={onSelectCustomer}
              onClose={onClose}
            />
          </Tab>
          <Tab eventKey="create" title="Skapa en ny kund">
            <Formik
              initialValues={initialFormValues}
              onSubmit={onSubmitCreate}
            >
              {formikBag => (
                <FormikForm>
                  <div className="pb-2">
                    <CustomerForm
                      isCreateForm
                      hidePasswordForm
                      formikBag={formikBag}
                      vertical
                    />
                  </div>
                  <ErrorAlert error={createMutation.error} />
                  <Modal.Footer className="pt-3 px-0 pb-0">
                    <SaveButton
                      type="submit"
                      isLoading={createMutation.isPending}
                      disabled={!formikBag.isValid || formikBag.isSubmitting}
                    >
                      {buttonCreateAndSelectNewLabel}
                    </SaveButton>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default SelectCustomerModal;

interface SelectCustomerModalSearchTabProps {
  onSelectCustomer: (customer: ICustomer) => void;
  buttonSelectExistingLabel?: string;
  onClose: () => void;
}

const SelectCustomerModalSearchTab: React.FC<SelectCustomerModalSearchTabProps> = React.memo(function SelectCustomerModalSearchTab (props: SelectCustomerModalSearchTabProps) {
  const { onSelectCustomer, buttonSelectExistingLabel, onClose } = props;

  const [search, setSearch] = useState('');
  const [confirmedSearch, setConfirmedSearch] = useState('');
  const [changedCustomerId, setChangedCustomerId] = useState<React.Key | null>(null);
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const searchQuery = useQuery<{rows: ICustomerWithReportCounts[]}, Error>({
    queryKey: ['SelectCustomerModal', confirmedSearch],
    queryFn: () => axios.get('/api/customers/list', {
      params: {search_term: confirmedSearch, limit: 5},
    }).then(r => r.data || []),
    placeholderData: keepPreviousData,
    enabled: confirmedSearch.length > 0,
  });

  // allow the onSelect outer handler to return a promise which causes the submit button
  // in the modal to load and prevent close until resolved
  const onSelectCustomerHandleReturnValue = (value:(void | Promise<any>)) => {
    if (!(value instanceof Promise)) {
      onClose();
      return;
    }

    setSubmitButtonIsLoading(true);
    value.finally(() => {
      setSubmitButtonIsLoading(true);
      onClose();
    });
  };

  const onSubmitSearch: React.FormEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setConfirmedSearch(search);
    searchQuery.refetch();
  };

  const onChangeSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  };

  const onChangeSelectedCustomer = (ev: React.FormEvent<HTMLInputElement>) => {
    const customerId = ev.currentTarget.value;
    setChangedCustomerId(customerId);
  };

  const onClickSelectExistingCustomer = () => {
    if (changedCustomerId === null || !searchResults.length) return;
    const selectedCustomer = searchResults.find(c => c.id === changedCustomerId);
    if (!selectedCustomer) return;
    onSelectCustomerHandleReturnValue(onSelectCustomer(selectedCustomer));
  };

  const searchResults = searchQuery.data?.rows ?? [];

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <Form onSubmit={onSubmitSearch}>
          <Form.Group className="w-50 d-flex gap-2">
            <Form.Control
              value={search}
              onChange={onChangeSearch}
              placeholder="Ange söksträng"
            />
            <LoadingButton
              type="submit"
              isLoading={searchQuery.isFetching}
            >
              Sök
            </LoadingButton>
          </Form.Group>
        </Form>
      </div>
      <ErrorAlert error={searchQuery.error} className="mt-4" />
      <Table bordered className="mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Namn</th>
            <th>E-post</th>
            <th>Rapporter</th>
          </tr>
        </thead>
        <tbody className="align-middle">
          {searchResults.length > 0 ? (
            <>
              {searchResults.map(customer => (
                <tr key={customer.id}>
                  <td>
                    <IdProvider>
                      {id => (
                        <Form.Check
                          id={id}
                          className="mb-0"
                          name="customer_id"
                          value={customer.id}
                          type="radio"
                          checked={changedCustomerId === customer.id}
                          onChange={onChangeSelectedCustomer}
                          label={customer.id}
                        />
                      )}
                    </IdProvider>
                  </td>
                  <td><CustomerFormatters.CustomerAnchor value={customer} /></td>
                  <td>{customer.email}</td>
                  <td>
                    <CustomerFormatters.CustomerReportCounts value={customer} />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={4}>Det finns inga sökresultat</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal.Footer className="pt-3 px-0 pb-0">
        <LoadingButton
          className="m-0"
          onClick={onClickSelectExistingCustomer}
          disabled={changedCustomerId === null}
          isLoading={submitButtonIsLoading}
        >
          {buttonSelectExistingLabel}
        </LoadingButton>
      </Modal.Footer>
    </>
  );
});
