import React, { useState, useRef, useId } from 'react';
import CompanyNameAndAddress from 'client/company/CompanyNameAndAddress';
import ErrorAlert from 'client/components/ErrorAlert';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import RoleGuard from 'client/guards/RoleGuard';
import {
  ReportQueueMetaFields,
  SaveOnCustomerCard,
  ReportQueueUpdateAlert,
} from 'client/reportQueue/ReportQueueFormHelpers';
import LoadingButton from 'client/buttons/LoadingButton';
import * as reportQueueUtils from 'client/reportQueue/utils';
import ValuationReportCompanyAccountsEditor from 'client/valuationReport/ValuationReportCompanyAccountsEditor';
import {
  IValuationReportFormData,
  IValuationReportsGetCompanyInfoData,
  IValuationReportsGetReportQueueInfoData,
} from 'client/valuationReport/types';
import {
  BooleanFormCheck,
  CompanyAccountsOverrideCheckbox,
  IsDemoCheckbox,
  LanguageChooser,
  DeliveryPreferences,
  RecallPreviousReportQueueFormDataNotice,
  AccountDateInputGroup,
} from 'client/components/ReportFormHelpers';
import { Card, Form } from 'react-bootstrap';
import { ICustomer } from 'client/customer/types';
import ValuationReportIndustryDataSubForm from 'client/valuationReport/ValuationReportIndustryDataSubForm';
import ValuationReportValueSubForm from 'client/valuationReport/ValuationReportValueSubForm';
import ValuationReportNumberOfAccountsInput from 'client/valuationReport/ValuationReportNumberOfAccountsInput';
import useReportQueueFormTools from 'client/hooks/useReportQueueFormTools';
import { ErrorLikeType } from 'client/utils/errors';
import HelperTooltip from 'client/components/HelperTooltip';
import useAuth from 'client/hooks/useAuth';

interface IValuationReportForm {
  isEditForm?: boolean;
  data: IValuationReportsGetCompanyInfoData | IValuationReportsGetReportQueueInfoData;
  form: IValuationReportFormData;
  setForm: React.Dispatch<React.SetStateAction<IValuationReportFormData>>;
  onSubmitForm: () => void;
  submitError?: ErrorLikeType;
  submitIsLoading?: boolean;
}

const ValuationReportForm: React.FC<IValuationReportForm> = React.memo(function ValuationReportForm (props: IValuationReportForm) {
  const {
    data,
    form,
    setForm,
    onSubmitForm,
    submitError,
    submitIsLoading,
    isEditForm,
  } = props;

  const {
    customer,
    company,
    groupStructure,
    groupAccounts,
    value:valueData,
    settings:settingsData,
  } = data;
  const previousReportQueue = (data as IValuationReportsGetCompanyInfoData).previousReportQueue;

  const {
    onChangeValue,
    onChangeAccountsOverrideValue,
    accounts,
    originalAccounts,
    onChangeAccountsTableSettings,
    onChangeUseGroupAccounts,
  } = useReportQueueFormTools({
    data,
    form,
    setForm,
    isEditForm,
  });

  const [customerData, setCustomerData] = useState<Partial<ICustomer> | null>(customer);

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmitForm();
  };

  const formId = useId();
  const formRef = useRef<HTMLFormElement>(null);

  const auth = useAuth();

  // recalls the customer id from a previously created report queue/valuation report
  const onClickRecallCustomerId = () => {
    if (!previousReportQueue || !previousReportQueue?.customer_id) return;
    const { customer_id } = previousReportQueue;
    setCustomerData(previousReportQueue.customer as ICustomer);
    setForm(prevForm => ({
      ...prevForm,
      customer_id,
    }));
  };

  // recalls some chosen form data from a previously __created__ report queue/valuation report
  const onClickRecallFormData = () => {
    setForm(prevForm => {
      return reportQueueUtils.mergeIntoFormDataFromPreviousValuationReportQueue(prevForm, previousReportQueue);
    });
  };

  return (
    <Form
      id={formId}
      onSubmit={onSubmit}
      ref={formRef}
    >
      <Card>
        <Card.Header className="py-3">
          <CompanyNameAndAddress company={company} />

          {previousReportQueue && (
            <RecallPreviousReportQueueFormDataNotice
              title="företagsvärdering"
              previousReportQueue={previousReportQueue}
              currentCustomerId={form.customer_id}
              onClickRecallCustomerId={onClickRecallCustomerId}
              onClickRecallFormData={onClickRecallFormData}
            />
          )}

        </Card.Header>
        <Card.Body className="border-bottom">

          <SaveOnCustomerCard
            name="customer_id"
            companyData={company}
            customerId={form.customer_id}
            onChangeValue={onChangeValue}
            customerRequired={!auth.isUserRole('admin')}
            customerEmailRequired={form.deliver_email}
            customerData={customerData}
            setCustomerData={setCustomerData}
          />

        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <LanguageChooser
            name="language"
            value={form.language}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <DeliveryPreferences
            customerData={customerData}
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom">
          <AccountDateInputGroup
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom">
          <ValuationReportNumberOfAccountsInput
            form={form}
            onChangeValue={onChangeValue}
            accounts={accounts}
          />
        </Card.Body>

        <Card.Body className="border-bottom">
          <ValuationReportValueSubForm
            accounts={accounts ?? []}
            numberOfAccounts={form.report_settings.number_of_accounts}
            overriddenValueFactor={form.report_settings.overridden_value_factor}
            valueData={valueData}
            settingsData={settingsData}
            onChangeValue={onChangeValue}
          />

        </Card.Body>

        <Card.Body>

          <IsDemoCheckbox
            form={form}
            onChangeValue={onChangeValue}
          />

          <CompanyAccountsOverrideCheckbox
            form={form}
            onChangeAccountsOverrideValue={onChangeAccountsOverrideValue}
          />

          {groupStructure && groupAccounts && (
            <>
              <BooleanFormCheck
                label={<>Gör koncernrapport med koncerndata från {groupStructure.company_name} ({groupStructure.org_number}) <HelperTooltip>Laddar in företagets koncernbokslut och skapar därmed en koncernrapport. Detta kommer framgå på företagsvärderingens framsida.</HelperTooltip></>}
                name="report_settings.company_use_group_accounts"
                checked={form.report_settings.company_use_group_accounts}
                onChangeValue={onChangeUseGroupAccounts}
              />
              <div className="alert alert-warning small p-1 mb-0 mt-2">
                <strong>OBS!</strong>{' '}
                Ändring av inställningen för koncerndata kommer att ladda in nya bokslut och skriva över ändringar av företagets bokslut.<br />Andra inställningar som "Bokslut att visa i rapporten" kan också ändras automatiskt.
              </div>
            </>
          )}

        </Card.Body>

        <RoleGuard role={null}>
          {form.report_settings.company_accounts_override && (
            <Card.Body className="p-0">
              <ValuationReportCompanyAccountsEditor
                name="report_settings.company_accounts"
                accounts={accounts ?? []}
                originalAccounts={originalAccounts ?? []}
                onChangeValue={onChangeValue}
                settings={form.form_options?.accounts_table}
                onChangeSetting={onChangeAccountsTableSettings}
                templateDateFrom={form.report_settings.accounts_from}
              />
            </Card.Body>
          )}
        </RoleGuard>

        <ValuationReportIndustryDataSubForm
          company={company}
          orgNumber={form.report_org_number}
          sniCode={form.report_settings.sni_code}
          nbrEmployeesInterval={form.report_settings.nbr_employees_interval}
          mostRecentAccountDate={form.report_settings.accounts_to}
          onChangeValue={onChangeValue}
        />

        <div className="p-0 border-top mt-3 pt-2">
          <Card.Title className="mx-3 mt-3 mb-0 border-bottom pb-1">
            Inställningar för rapportkö
          </Card.Title>
          <div className="px-3">
            <ReportQueueMetaFields
              isEditForm={isEditForm}
              form={form}
              onChangeValue={onChangeValue}
            />
          </div>
        </div>

        <Card.Footer className="p-2">
          <ErrorAlert className="mb-2" error={submitError} />
          {isEditForm && Boolean((data as IValuationReportsGetReportQueueInfoData).reportQueue.valuation_report) && (
            <ReportQueueUpdateAlert />
          )}
          <div className="d-flex justify-content-end gap-2">
            <InspectObjectModalButton
              object={{form, data}}
              className="px-2 d-flex align-items-center"
              size="lg"
            />
            <LoadingButton
              type="submit"
              isLoading={submitIsLoading}
              disabled={submitIsLoading}
            >
              {isEditForm ? 'Uppdatera värderingsrapport' : 'Skapa värderingsrapport'}
            </LoadingButton>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
});
export default ValuationReportForm;
