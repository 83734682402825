import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';
import RefreshButton from 'client/buttons/RefreshButton';
import { Card } from 'react-bootstrap';
import { ICsGroupStructure } from 'client/cs/types';
import { CompanyAnchor } from 'client/company/CompanyFormatters';
import { CountryFlag } from 'client/components/Flags';
import useAuth from 'client/hooks/useAuth';

interface CompanyGroupStructureResponse {
  top: null | ICsGroupStructure;
  relations: ICsGroupStructure[];
}

interface CompanyGroupStructureWithChildren extends ICsGroupStructure {
  children: CompanyGroupStructureWithChildren[];
}

interface CompanyGroupStructureCardProps {
  className?: string;
  orgNumber: string;
}

export default function CompanyGroupStructureCard (props: CompanyGroupStructureCardProps) {
  const { orgNumber, className } = props;

  const auth = useAuth();
  let url = `/api/company/${orgNumber}/groupStructure`;
  if (auth.isCustomer) url = `/api/as_customer/company/${orgNumber}/groupStructure`;

  const query = useQuery<CompanyGroupStructureResponse, Error>({
    queryKey: [url],
  });

  const data = query.data;

  const tree = data && data.relations && data.top ? groupStructureToTree(data.relations, data.top) : null;

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0" as="h6">
          Koncernstruktur
        </Card.Title>
        <RefreshButton onClick={query.refetch} size="sm" />
      </Card.Header>
      <BlockSpinner isLoading={query.isLoading} className="m-3" />
      <ErrorAlert error={query.error} className="m-3" />
      {query.isSuccess && data && (
        <Card.Body>
          {tree ? (
            <ul className="mb-1">
              <li>
                <GroupStructureListItem company={tree} />
              </li>
            </ul>
          ) : (
            <p className="mb-0">Ingen koncernstruktur finns tillgänglig</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

interface GroupStructureListItemProps {
  company: CompanyGroupStructureWithChildren;
}

function GroupStructureListItem (props: GroupStructureListItemProps) {
  const { company } = props;
  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        {company.company_country_code === 'SE' ? (
          <>
            <CompanyAnchor value={company} />
          </>
        ) : (
          <>
            {company.company_name}
          </>
        )}
        <CountryFlag countryCode={company.company_country_code} />
        <small className="text-muted">{company.org_number}</small>
      </div>
      {company.children.length > 0 && (
        <ul>
          {company.children.map(child => (
            <li key={child.org_number} className="pt-2">
              <GroupStructureListItem key={child.org_number} company={child} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

function groupStructureToTree (list: ICsGroupStructure[], parent: ICsGroupStructure, initialKey: keyof ICsGroupStructure = 'closest_group_mother_orgnumber'): CompanyGroupStructureWithChildren {
  const children = list.filter(company => {
    return company[initialKey] === parent.org_number;
  }).map(parent => {
    return groupStructureToTree(list, parent);
  });
  return {...parent, children};
}
