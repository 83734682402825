import React from 'react';
import { Field, FormikErrors, FormikProps } from 'formik';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Row, Col, Form } from 'react-bootstrap';
import AccountChangePasswordFormFields, { AccountChangePasswordFormFieldsType } from 'client/account/AccountChangePasswordFormFields';
import { UserRole } from 'client/user/types';
import PasswordFormControl, {PasswordFormText, PasswordRandomButton, passwordRegexp} from 'client/form/PasswordFormControl';
import useAuth from 'client/hooks/useAuth';
import HelperTooltip from 'client/components/HelperTooltip';

export interface CreateUserFormFields {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  ip_addresses_allowed: string;
  password: string;
}

export interface UpdateUserFormFields {
  name: string;
  email: string;
  role: UserRole;
  ip_addresses_allowed: string;
  company_monitored_limit: '' | number;
  change_password: boolean;
  password: string;
  active: boolean;
}

interface UserFormProps {
  isCreateForm?: boolean;
  disabled?: boolean;
  vertical?: boolean;
  formikBag: FormikProps<UpdateUserFormFields> | FormikProps<CreateUserFormFields>;
}

const UserForm: React.FC<UserFormProps> = React.memo(function UserForm (props: UserFormProps) {
  const {
    isCreateForm = false,
    disabled = false,
    vertical = false,
    formikBag,
  } = props;
  const auth = useAuth();
  const colProps = vertical ? {sm: 12} : {lg: 6, md: 12};
  return (
    <Row>
      {isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  ID/Inloggning
                </Form.Label>
                <Field
                  as={Form.Control}
                  disabled={disabled}
                  id={id}
                  name="id"
                  placeholder="Ange ett inloggningsnamn"
                  isInvalid={Boolean((formikBag as FormikProps<CreateUserFormFields>).errors.id)}
                  validate={validateId}
                  autoComplete="username"
                  required
                />
                <ErrorFormControlFeedback
                  error={(formikBag as FormikProps<CreateUserFormFields>).errors.id}
                  touched={(formikBag as FormikProps<CreateUserFormFields>).touched.id}
                />
                <Form.Text>
                  Bara små bokstäver, bindestreck eller punkt.
                </Form.Text>
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Namn
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="name"
                autoComplete="name"
                placeholder="Ange ett namn"
                isInvalid={Boolean(formikBag.errors.name)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.name}
                touched={formikBag.touched.name}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                E-post
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="email"
                autoComplete="email"
                validate={validateEmail}
                placeholder="Ange en e-postadress"
                isInvalid={Boolean(formikBag.errors.email)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.email}
                touched={formikBag.touched.email}
              />
              <Form.Text>
                Bara små bokstäver.
              </Form.Text>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Roll
              </Form.Label>
              <Field
                as={Form.Select}
                disabled={disabled}
                id={id}
                name="role"
                placeholder="Ange en roll"
                isInvalid={Boolean(formikBag.errors.role)}
                required
              >
                <option value="">Ange en roll</option>
                <option value="noone" disabled>Ingen roll</option>
                <option value="account_manager">Account Manager</option>
                <option value="coordinator" disabled={!auth.isUserRole('admin')}>Koordinator</option>
                <option value="team_leader" disabled={!auth.isUserRole('admin')}>Team Leader</option>
                <option value="admin" disabled={!auth.isUserRole('admin')}>Administratör</option>
              </Field>
              <ErrorFormControlFeedback
                error={formikBag.errors.role}
                touched={formikBag.touched.role}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      {!isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  Status
                </Form.Label>
                <Field
                  className="mb-0"
                  as={Form.Select}
                  disabled={disabled}
                  value={String((formikBag.values as UpdateUserFormFields).active)}
                  id={id}
                  name="active"
                >
                  <option value="false">Användaren är inaktiv</option>
                  <option value="true">Användaren är aktiv och kan logga in</option>
                </Field>
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Tillåtna IP-adresser för inloggning
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="ip_addresses_allowed"
                placeholder="Alla adresser tillåtna"
                isInvalid={Boolean(formikBag.errors.ip_addresses_allowed)}
                validate={validateIpAddressesAllowed}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.ip_addresses_allowed}
                touched={formikBag.touched.ip_addresses_allowed}
              />
              <Form.Text>
                Ange en eller flera IP-adresser separerade med kommatecken.
              </Form.Text>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      {!isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  Max antal bevakade företag{' '}
                  <HelperTooltip>Högsta antal bevakade företag som användaren själv kan lägga in på sitt konto. Tomt värde inaktiverar tillgången till bevakningsfunktionen. Om ett lägre värde än användarens nuvarande antal bevakade företag väljs så kommer de äldsta inlagda företagen tas bort från användarens bevakningslista.</HelperTooltip>
                </Form.Label>
                <Field
                  as={Form.Control}
                  disabled={disabled}
                  id={id}
                  type="number"
                  name="company_monitored_limit"
                  placeholder="Ingen tillgång till bevakningsfunktionen"
                  isInvalid={Boolean((formikBag.errors as FormikErrors<UpdateUserFormFields>).company_monitored_limit)}
                  step="1"
                  min="0"
                  max="1000"
                />
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      <Col sm={12}>
        {isCreateForm ? (
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  Lösenord
                </Form.Label>
                <div className="d-flex gap-2">
                  <Field
                    as={PasswordFormControl}
                    disabled={disabled}
                    id={id}
                    name="password"
                    placeholder="Ange ett lösenord"
                    isInvalid={Boolean(formikBag.errors.password)}
                    validate={validatePassword}
                    required
                  />
                  <PasswordRandomButton
                    onGenerated={password => formikBag.setFieldValue('password', password)}
                  />
                </div>
                <ErrorFormControlFeedback
                  error={formikBag.errors.password}
                  touched={formikBag.touched.password}
                />
                <PasswordFormText />
              </Form.Group>
            )}
          </IdProvider>
        ) : (
          <AccountChangePasswordFormFields
            disabled={disabled}
            formikBag={formikBag as unknown as FormikProps<AccountChangePasswordFormFieldsType>}
            checkboxLabel="Ändra användarens lösenord"
          />
        )}
      </Col>
    </Row>
  );
});

export default UserForm;

const ipAddressRegExp = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/;

function validateIpAddressesAllowed (value: string) {
  if (!value) return;
  const ips = value.split(',').map(s => s.trim());
  if (ips.some(ip => !ipAddressRegExp.test(ip))) {
    return 'Någon av IP-adresserna är ogiltig';
  }
}

function validateId (value: string) {
  if (!value) return;
  if (!/^[a-zåäö.-]+$/.test(value)) {
    return 'Värdet är ogiltigt';
  }
}

function validateEmail (value: string) {
  if (!value) return;
  if (/[A-ZÅÖÄ]/.test(value)) {
    return 'Värdet är ogiltigt';
  }
}

function validatePassword (value: string) {
  if (!value) return;
  if (!passwordRegexp.test(value)) {
    return 'Lösenordet uppfyller inte kraven';
  }
}
