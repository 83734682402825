import React, { useMemo, useRef, useState, useId } from 'react';
import CompanyNameAndAddress from 'client/company/CompanyNameAndAddress';
import ErrorAlert from 'client/components/ErrorAlert';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import {
  ReportQueueMetaFields,
  SaveOnCustomerCard,
} from 'client/reportQueue/ReportQueueFormHelpers';
import { IValuationReportFormData } from 'client/valuationReport/types';
import { IIndustryReportFormData } from 'client/industryReport/types';
import { OrgNumberToCompanyName } from 'client/components/OrgNumberInput';
import LoadingButton from 'client/buttons/LoadingButton';
import useAuth from 'client/hooks/useAuth';
import { TagFormList, TagFormAddOrgNumber } from 'client/components/TagFormTools';
import * as reportQueueUtils from 'client/reportQueue/utils';
import ValuationReportCompanyAccountsEditor from 'client/valuationReport/ValuationReportCompanyAccountsEditor';
import IndustryReportMinimumNumberOfCompaniesRequiredInput from 'client/industryReport/IndustryReportMinimumNumberOfCompaniesRequiredInput';
import {
  AccountDateInputGroup,
  BooleanFormCheck,
  CompanyAccountsOverrideCheckbox,
  CompanyExcludeCheckbox,
  IsDemoCheckbox,
  LanguageChooser,
  RecallPreviousReportQueueFormDataNotice,
  DeliveryPreferences,
} from 'client/components/ReportFormHelpers';
import { Card, Form } from 'react-bootstrap';
import { ICustomer } from 'client/customer/types';
import ValuationReportIndustryDataSubForm from 'client/valuationReport/ValuationReportIndustryDataSubForm';
import ValuationReportNumberOfAccountsInput from 'client/valuationReport/ValuationReportNumberOfAccountsInput';
import ValuationReportValueSubForm from 'client/valuationReport/ValuationReportValueSubForm';
import useReportQueueFormTools from 'client/hooks/useReportQueueFormTools';
import RoleGuard from 'client/guards/RoleGuard';
import { ErrorLikeType } from 'client/utils/errors';
import { IReportQueueCreateValuationAndIndustryReportFormData } from 'client/reportQueue/types';
import { GetCompanyInfoData } from 'client/reportQueue/ReportQueueCreatePage';
import { TNumberOfEmployeesIntervals } from 'client/accounts/types';
import IndustryReportBranchAndSNICodeSelector from 'client/industryReport/IndustryReportBranchAndSNICodeSelector';
import IdProvider from 'client/components/IdProvider';
import HelperTooltip from 'client/components/HelperTooltip';

export type CreateDestination = 'industryReport' | 'valuationReport';

interface ReportQueueCreateValuationAndIndustryReportForm {
  data: GetCompanyInfoData;
  form: IReportQueueCreateValuationAndIndustryReportFormData;
  setForm: React.Dispatch<React.SetStateAction<IReportQueueCreateValuationAndIndustryReportFormData>>;
  onSubmitForm: (onCreateDestination: CreateDestination) => void;
  submitError?: ErrorLikeType;
  submitIsLoading?: boolean;
}

const ReportQueueCreateValuationAndIndustryReportForm: React.FC<ReportQueueCreateValuationAndIndustryReportForm> = React.memo(function ValuationReportForm (props: ReportQueueCreateValuationAndIndustryReportForm) {
  const {
    data:uncombinedData,
    form,
    setForm,
    onSubmitForm,
    submitError,
    submitIsLoading,
  } = props;

  const [onCreateDestination, setOnCreateDestination] = useState<CreateDestination>('valuationReport');

  const onChangeOnCreateDestinatioCheckbox = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setOnCreateDestination(ev.target.value as CreateDestination);
  };

  const data = {
    ...(uncombinedData?.valuationReport ?? {}),
    ...(uncombinedData?.industryReport ?? {}),
  };

  const {
    customer,
    company,
    groupStructure,
    groupAccounts,
    value:valueData,
    settings:settingsData,
    branches,
  } = data;

  const hasIndustryReport = Boolean(uncombinedData.industryReport);
  const hasValuationReport = Boolean(uncombinedData.valuationReport);

  const previousIndustryReportQueue = uncombinedData.industryReport.previousReportQueue ?? null;
  const previousValuationReportQueue = uncombinedData.valuationReport.previousReportQueue ?? null;

  const {
    onChangeValue,
    onChangeAccountsOverrideValue,
    accounts,
    originalAccounts,
    onChangeAccountsTableSettings,
    onChangeUseGroupAccounts,
  } = useReportQueueFormTools({
    data,
    form,
    setForm,
  });

  const [customerData, setCustomerData] = useState<Partial<ICustomer> | null>(customer);

  const onSubmitReportCreateForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmitForm(onCreateDestination);
  };

  const formId = useId();
  const formRef = useRef<HTMLFormElement>(null);

  // recalls the customer id from a previously created report queue/valuation report
  const onClickRecallValuationCustomerId = () => {
    if (!previousValuationReportQueue || !previousValuationReportQueue?.customer_id) return;
    const { customer_id } = previousValuationReportQueue;
    setCustomerData(previousValuationReportQueue.customer as ICustomer);
    setForm(prevForm => ({
      ...prevForm,
      customer_id,
    }));
  };

  const onClickRecallIndustryCustomerId = () => {
    if (!previousIndustryReportQueue || !previousIndustryReportQueue?.customer_id) return;
    const { customer_id } = previousIndustryReportQueue;
    setCustomerData(previousIndustryReportQueue.customer as ICustomer);
    setForm(prevForm => ({
      ...prevForm,
      customer_id,
    }));
  };

  // recalls some chosen form data from a previously __created__ report queue/valuation report
  const onClickRecallValuationFormData = () => {
    setForm(prevForm => {
      const result = reportQueueUtils.mergeIntoFormDataFromPreviousValuationReportQueue(prevForm as IValuationReportFormData, previousValuationReportQueue);
      return result as IReportQueueCreateValuationAndIndustryReportFormData;
    });
  };

  // recalls some chosen form data from a previously __created__ report queue/industry report
  const onClickRecallIndustryFormData = () => {
    setForm(prevForm => {
      const result = reportQueueUtils.mergeIntoFormDataFromPreviousIndustryReportQueue(prevForm as IIndustryReportFormData, previousIndustryReportQueue);
      return result as IReportQueueCreateValuationAndIndustryReportFormData;
    });
  };

  const defaultBranchIdValue = useMemo(() => {
    if (!uncombinedData.industryReport) return;
    return uncombinedData.industryReport?.form?.report_settings?.branch_id ?? null;
  }, [data]);

  const auth = useAuth();

  return (
    <Form
      id={formId}
      onSubmit={onSubmitReportCreateForm}
      ref={formRef}
    >
      <Card>
        <Card.Header className="py-3">
          <CompanyNameAndAddress company={company} />

          {previousValuationReportQueue && (
            <RecallPreviousReportQueueFormDataNotice
              title="företagsvärdering"
              previousReportQueue={previousValuationReportQueue}
              currentCustomerId={form.customer_id}
              onClickRecallCustomerId={onClickRecallValuationCustomerId}
              onClickRecallFormData={onClickRecallValuationFormData}
            />
          )}

          {previousIndustryReportQueue && (
            <RecallPreviousReportQueueFormDataNotice
              title="branschrapport"
              previousReportQueue={previousIndustryReportQueue}
              currentCustomerId={form.customer_id}
              onClickRecallCustomerId={onClickRecallIndustryCustomerId}
              onClickRecallFormData={onClickRecallIndustryFormData}
            />
          )}

        </Card.Header>
        <Card.Body className="border-bottom">

          <SaveOnCustomerCard
            name="customer_id"
            setCustomerData={setCustomerData}
            customerData={customerData}
            companyData={company}
            customerId={form.customer_id}
            onChangeValue={onChangeValue}
            customerRequired={!auth.isUserRole('admin')}
            customerEmailRequired={form.deliver_email}
          />

        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <LanguageChooser
            name="language"
            value={form.language}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <DeliveryPreferences
            customerData={customerData}
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="d-flex gap-3 border-bottom flex-wrap">
          <AccountDateInputGroup
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body>

          <IsDemoCheckbox
            form={form}
            onChangeValue={onChangeValue}
          />

          <CompanyAccountsOverrideCheckbox
            form={form}
            onChangeAccountsOverrideValue={onChangeAccountsOverrideValue}
          />

          {groupStructure && groupAccounts && (
            <>
              <BooleanFormCheck
                label={<>Gör koncernrapport med koncerndata från {groupStructure.company_name} ({groupStructure.org_number}) <HelperTooltip>Laddar in företagets koncernbokslut och skapar därmed en koncernrapport. Detta kommer framgå på företagsvärderingens framsida. Företagets koncernbokslut kommer även att användas i branschrapporten.</HelperTooltip></>}
                name="report_settings.company_use_group_accounts"
                checked={form.report_settings.company_use_group_accounts}
                onChangeValue={onChangeUseGroupAccounts}
              />
              <div className="alert alert-warning small p-1 mb-0 mt-2">
                <strong>OBS!</strong>{' '}
                Ändring av inställningen för koncerndata kommer att ladda in nya bokslut och skriva över dina ändringar av företagets bokslut.<br />Andra inställningar som "Bokslut att visa i rapporten" kan också ändras automatiskt.
              </div>
            </>
          )}

        </Card.Body>

        <RoleGuard role={null}>
          {form.report_settings.company_accounts_override && (
            <Card.Body className="p-0">
              <ValuationReportCompanyAccountsEditor
                name="report_settings.company_accounts"
                accounts={accounts ?? []}
                originalAccounts={originalAccounts ?? []}
                onChangeValue={onChangeValue}
                settings={form.form_options?.accounts_table}
                onChangeSetting={onChangeAccountsTableSettings}
              />
            </Card.Body>
          )}
        </RoleGuard>

        {hasValuationReport && (
          <div className="p-0 border-top pt-2">
            <Card.Title className="mx-3 mt-3 mb-0 border-bottom pb-1" as="h3">
              Inställningar för företagsvärderingen
            </Card.Title>

            <Card.Body className="border-bottom">
              <ValuationReportNumberOfAccountsInput
                form={form}
                onChangeValue={onChangeValue}
                accounts={accounts}
              />
            </Card.Body>

            <Card.Body>
              <ValuationReportValueSubForm
                accounts={accounts ?? []}
                numberOfAccounts={form.report_settings.number_of_accounts as number}
                overriddenValueFactor={form.report_settings.overridden_value_factor as (number | null)}
                valueData={valueData}
                settingsData={settingsData}
                onChangeValue={onChangeValue}
              />

            </Card.Body>

            <ValuationReportIndustryDataSubForm
              company={company}
              orgNumber={form.report_org_number}
              sniCode={form.report_settings.sni_code as string}
              nbrEmployeesInterval={form.report_settings.nbr_employees_interval as TNumberOfEmployeesIntervals}
              mostRecentAccountDate={form.report_settings.accounts_to}
              onChangeValue={onChangeValue}
            />

          </div>
        )}

        {hasIndustryReport && (
          <div className="p-0 border-top mt-3 pt-2">
            <Card.Title className="mx-3 mt-3 mb-0 border-bottom pb-1" as="h3">
              Inställningar för branschrapporten
            </Card.Title>
            <Card.Body className="border-bottom">
              <IndustryReportBranchAndSNICodeSelector
                branches={branches}
                branchIdValue={form.report_settings.branch_id as (string | null)}
                defaultBranchIdValue={defaultBranchIdValue as (string | null)}
                sniCodesValue={form.report_settings.sni_codes as string[]}
                onChangeValue={onChangeValue}
              />
              <div className="mt-3 border-top pt-3">
                <IdProvider>
                  {id => (
                    <>
                      <Form.Label htmlFor={id}>
                        Inkludera ytterligare företag i branschrapporten
                      </Form.Label>
                      <div className="d-flex flex-wrap gap-3">
                        <TagFormList
                          name="report_settings.include_companies"
                          values={form.report_settings.include_companies}
                          onChangeValues={onChangeValue}
                          valueFormatter={OrgNumberToCompanyName}
                        />
                        <TagFormAddOrgNumber
                          id={id}
                          name="report_settings.include_companies"
                          values={form.report_settings.include_companies}
                          onChangeValues={onChangeValue}
                          blockedValues={[form.report_org_number]}
                        />
                      </div>
                    </>
                  )}
                </IdProvider>
              </div>
              <div className="d-flex border-top pt-3 mt-3">
                <IndustryReportMinimumNumberOfCompaniesRequiredInput
                  onChangeValue={onChangeValue}
                  form={form}
                />
              </div>
              <div className="mt-3 border-top pt-3">
                <CompanyExcludeCheckbox
                  form={form}
                  onChangeValue={onChangeValue}
                />
              </div>
            </Card.Body>
          </div>
        )}

        <div className="p-0 border-top mt-3 pt-2">
          <Card.Title className="mx-3 mt-3 mb-0 border-bottom pb-1" as="h3">
            Inställningar för rapportkö
          </Card.Title>
          <div className="px-3">
            <ReportQueueMetaFields
              form={form}
              onChangeValue={onChangeValue}
            />
          </div>
        </div>

        <Card.Footer className="p-2">
          <ErrorAlert className="mt-2" error={submitError} />
          <div className="d-flex justify-content-end gap-2">
            <div className="d-flex align-items-center">
              <IdProvider>
                {id => (
                  <Form.Check
                    inline
                    id={id}
                    type="radio"
                    name="onCreateDestination"
                    value="industryReport"
                    label="Gå till branschrapporten"
                    checked={onCreateDestination === 'industryReport'}
                    onChange={onChangeOnCreateDestinatioCheckbox}
                  />
                )}
              </IdProvider>
              <IdProvider>
                {id => (
                  <Form.Check
                    inline
                    id={id}
                    type="radio"
                    name="onCreateDestination"
                    value="valuationReport"
                    label="Gå till företagsvärderingen"
                    checked={onCreateDestination === 'valuationReport'}
                    onChange={onChangeOnCreateDestinatioCheckbox}
                  />
                )}
              </IdProvider>
            </div>
            <InspectObjectModalButton
              object={{form, data}}
              className="px-2 d-flex align-items-center"
              size="lg"
            />
            <LoadingButton
              type="submit"
              isLoading={submitIsLoading}
              disabled={submitIsLoading}
            >
              Skapa företagsvärdering och branschrapport
            </LoadingButton>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
});
export default ReportQueueCreateValuationAndIndustryReportForm;
